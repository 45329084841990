import React, { useState, useEffect } from 'react';

const ServiceShutdownPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [dontShowToday, setDontShowToday] = useState(false);

  useEffect(() => {
    const lastShown = localStorage.getItem('popupLastShown');
    const today = new Date().toDateString();
    if (lastShown !== today) {
      setIsVisible(true);
    }
  }, []);

  const closePopup = () => {
    if (dontShowToday) {
      const today = new Date().toDateString();
      localStorage.setItem('popupLastShown', today);
    }
    setIsVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.id === 'popupOverlay') {
      closePopup();
    }
  };

  if (!isVisible) return null;

  return (
    <div id="popupOverlay" style={overlayStyle} onClick={handleOverlayClick}>
      <div style={popupStyle} onClick={(e) => e.stopPropagation()}> {/* Prevent background click from closing */}
        <button style={closeButtonStyle} onClick={closePopup}>×</button>
        <h2 style={headerStyle}>전주맛배달 신규 앱 출시 안내</h2>
        <p style={paragraphStyle}>
          전주맛배달 플랫폼이 변경되어 신규 앱이 출시됩니다. <br />
          기존 앱은 <b>12월 31일</b>까지 정상 운영 후<br />
          <b>2025년 1월 1일</b>부터 사용이 중단됩니다.<br />
          <br />아래 링크에서 신규 앱을 미리 설치해주세요!<br />
          <b style={highlightTextStyle}>안드로이드</b>{' '}
          <a
            href="https://vo.la/aVBujd"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: '#e4134c' }}
          >
            https://vo.la/aVBujd
          </a>
          <br />
          <b style={highlightTextStyle}>아이폰</b>{' '}
          <a
            href="https://vo.la/UiVFxn"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: '#e4134c' }}
          >
            https://vo.la/UiVFxn
          </a>
        </p>
        <div style={checkboxContainerStyle}>
          <input
            type="checkbox"
            id="dontShowToday"
            checked={dontShowToday}
            onChange={() => setDontShowToday(!dontShowToday)}
            style={checkboxStyle}
          />
          <label htmlFor="dontShowToday" style={checkboxLabelStyle}>
            오늘 하루 보지 않기
          </label>
        </div>
      </div>
    </div>
  );
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 999,
};

const popupStyle = {
  position: 'relative',
  width: '85%',
  maxWidth: '320px',
  background: '#fff',
  borderRadius: '10px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  textAlign: 'center',
  padding: '20px',
};

const headerStyle = {
  fontSize: '18px',
  marginBottom: '10px',
  color: '#e4134c', // 전주맛배달 대표 색상
};

const paragraphStyle = {
  fontSize: '14px',
  color: '#555',
  marginBottom: '20px',
  lineHeight: '1.5',
};

const highlightTextStyle = {
  color: '#e4134c',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'none',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#999',
};

const checkboxContainerStyle = {
  marginTop: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start', /* 왼쪽 정렬 */
};

const checkboxLabelStyle = {
  marginLeft: '5px',
  fontSize: '14px',
  color: '#555',
  cursor: 'pointer',
};

const checkboxStyle = {
    appearance: 'auto', // 기본 브라우저 스타일 적용
    WebkitAppearance: 'checkbox', // Chrome, Safari 호환성
    width: '16px',
    height: '16px',
    margin: '0',
    padding: '0',
    cursor: 'pointer',
    border: '1px solid #ccc',
    backgroundColor: '#fff',
  };
export default ServiceShutdownPopup;
